import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/medals',
		name: 'Medals',
		component: function () {
			return import(/* webpackChunkName: "about" */ '../views/Medals.vue')
		}
	},
	{
		path: '/medallists/:countryCode?',
		name: 'Medallists',
		component: function () {
			return import(/* webpackChunkName: "about" */ '../views/Medallists.vue')
		}
	},
	{
		path: '/athletes/:countryCode',
		name: 'Athletes',
		component: function () {
			return import(/* webpackChunkName: "about" */ '../views/Athletes.vue')
		}
	},
	{
		path: '/countries',
		name: 'Countries',
		component: function () {
			return import(/* webpackChunkName: "about" */ '../views/Countries.vue')
		}
	}
]

const router = new VueRouter({
	routes
})

export default router
