<template>
	<div id="app">
		<Clock :diff="9" :cur="2"></Clock>
		<div class="max-w-sm mx-auto mb-2">
			<div class="flex justify-between">
				<router-link class="bg-red-100 px-3 border border-red-200 shadow py-1 rounded-lg" to="/">Home</router-link>
				<router-link class="bg-red-100 px-3 border border-red-200 shadow py-1 rounded-lg" to="/countries">Countries</router-link>
				<router-link class="bg-red-100 px-3 border border-red-200 shadow py-1 rounded-lg" to="/medals">Medals</router-link>
				<router-link class="bg-red-100 px-3 border border-red-200 shadow py-1 rounded-lg" to="/medallists">Medallists</router-link>
			</div>
		</div>
		<router-view/>
	</div>
</template>

<script>

import Clock from '@/components/Clock.vue';

export default {
	components: {
		Clock
	}
};

</script>

<style lang="scss"></style>