<template>
	<div class="max-w-sm mx-auto flex justify-between gap-2 my-3">

		<div v-for="c in clock" class="w-1/2 overflow-hidden rounded border shadow">
			<div class="flex bg-gray-50 justify-center py-2 gap-2 text-lg border-b">
				<div class="font-bold">{{ days[c.weekDay] }}</div>
				<div class="font-semibold">
					{{ c.day }}<!--<span>/</span><span>{{ c.month }}</span>-->
				</div>
			</div>
			<div class="flex bg-red-10e0 text-3xl justify-evenly p-2">
				<div class="font-bold text-center w-3/12">{{ c.hour | round }}</div>
				<div class="text-gray-300 text-base self-center text-center">:</div>
				<div class="font-bold text-center w-3/12">{{ c.minutes | round }}</div>
				<div class="text-gray-300 text-base self-center text-center">:</div>
				<div class="font-light text-center w-2/12 text-base">{{ c.seconds | round }}</div>
			</div>
		</div>

	</div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
	filters: {
		round: function(a) {

			if(a < 10)
				return '0' + a;
			return a;
		}
	},
	name: 'Clock',
	data: function() {
		return {
			clock: {
				local: false,
				remote: false
			},
			days: [
				'',
				'Lunedì',
				'Martedì',
				'Mercoledì',
				'Giovedì',
				'Venerdì',
				'Sabato',
				'Domenica'
			]
		}
	},
	methods: {
		tick: function() {

			var l = moment.utc().add(this.cur, 'hours');
			var r = moment.utc().add(this.diff, 'hours')

			var form = function(what) {
				return {
					hour: what.hours(),
					minutes: what.minutes(),
					seconds: what.seconds(),
					day: what.date(),
					month: what.month() + 1,
					weekDay: what.isoWeekday(),
				}				
			};

			this.clock.local = form(l);
			this.clock.remote = form(r);

			_.delay(function() { this.tick() }.bind(this), 100);
		}
	},
	props: {
		diff: Number,
		cur: Number
	},
	created: function() {
		this.tick();
	}
};
</script>