import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueResource from 'vue-resource';

import "tailwindcss/tailwind.css"

window.api_url = 'https://o20.fdrcq.com/api/';

Vue.use(VueResource);

Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
